import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">
<path d="M0 12800 l0 -12800 12800 0 12800 0 0 12800 0 12800 -12800 0 -12800
0 0 -12800z m16735 9371 c8 -14 6 -3224 -2 -3232 -10 -9 -1123 20 -1993 52
-267 9 -565 18 -1375 39 -214 5 -480 14 -590 20 -110 5 -285 11 -389 13 l-189
2 -108 -330 c-120 -365 -134 -407 -309 -945 -67 -206 -130 -399 -141 -428 -23
-66 -24 -88 -2 -96 9 -4 305 -9 657 -12 630 -5 782 -11 1041 -40 190 -22 248
-31 423 -65 1300 -252 2458 -967 3268 -2020 510 -663 843 -1389 1015 -2214 14
-70 48 -280 68 -425 25 -179 31 -304 31 -649 0 -346 -7 -508 -29 -646 -6 -33
-15 -96 -22 -140 -68 -468 -179 -864 -365 -1303 -275 -648 -638 -1186 -1144
-1693 -281 -282 -464 -434 -808 -673 -190 -132 -578 -343 -847 -461 -493 -216
-1054 -361 -1585 -410 -187 -18 -813 -20 -1011 -4 -155 12 -544 75 -704 114
-737 176 -1383 478 -1989 930 -379 282 -784 688 -1046 1048 -19 26 -48 64 -64
85 -15 20 -63 89 -105 152 -201 299 -378 632 -502 943 -67 168 -177 513 -166
523 1 2 18 -41 36 -94 120 -346 291 -720 465 -1017 487 -831 1206 -1517 2059
-1965 571 -299 1157 -478 1862 -566 205 -26 889 -27 1090 -1 625 79 1126 218
1620 450 877 411 1594 1028 2120 1822 129 195 175 273 281 480 109 212 210
448 286 670 66 189 121 378 137 465 5 25 21 97 35 160 38 163 78 450 96 683
14 184 12 553 -5 757 -14 173 -55 483 -75 570 -6 25 -24 106 -41 180 -149 672
-463 1337 -907 1928 -259 344 -648 728 -1006 992 -366 269 -831 521 -1241 672
-416 154 -871 256 -1330 298 -119 11 -474 11 -700 1 -126 -6 -105 -7 200 -14
397 -9 587 -26 855 -73 881 -156 1719 -557 2393 -1146 312 -274 515 -497 764
-838 497 -683 824 -1538 902 -2360 30 -309 33 -378 28 -575 -15 -574 -78 -954
-242 -1466 -92 -287 -282 -719 -400 -908 -23 -36 -63 -103 -90 -148 -169 -286
-509 -704 -786 -965 -836 -789 -1865 -1244 -3009 -1329 -317 -24 -807 1 -1135
57 -1082 185 -2059 726 -2789 1544 -270 304 -489 619 -678 980 -146 278 -267
581 -330 825 -35 140 -30 144 15 10 103 -303 225 -585 360 -830 285 -519 665
-979 1132 -1371 160 -134 467 -346 664 -458 118 -67 440 -225 541 -265 489
-194 926 -297 1448 -342 222 -19 686 -7 924 25 660 87 1198 268 1760 592 429
247 839 580 1144 929 389 444 662 891 860 1405 83 215 175 517 204 665 4 22
17 87 29 145 25 119 33 173 58 385 21 188 24 737 5 925 -88 867 -414 1692
-936 2365 -162 210 -234 290 -418 470 -308 303 -593 519 -948 717 -549 307
-1033 467 -1700 563 -129 19 -713 38 -830 27 -77 -7 -65 -8 135 -15 324 -12
474 -24 709 -57 898 -129 1746 -539 2428 -1174 460 -429 846 -994 1086 -1591
152 -378 262 -835 302 -1250 13 -140 13 -656 0 -800 -77 -836 -393 -1646 -898
-2300 -116 -150 -201 -247 -351 -398 -213 -217 -388 -366 -621 -529 -513 -359
-1059 -590 -1685 -712 -522 -102 -1129 -100 -1655 4 -858 171 -1637 589 -2244
1204 -460 467 -781 972 -997 1571 -42 118 -104 334 -113 400 l-6 40 14 -35 c8
-19 30 -89 51 -155 87 -282 257 -656 421 -925 313 -515 747 -971 1245 -1307
487 -328 1038 -553 1612 -658 275 -50 664 -82 862 -70 446 25 690 62 1027 155
1165 321 2174 1172 2668 2251 103 224 209 516 255 699 9 36 22 88 30 115 17
57 31 130 45 230 6 38 12 76 15 85 3 8 14 87 25 175 25 201 28 677 5 877 -105
939 -477 1756 -1099 2415 -98 105 -244 247 -308 299 -328 273 -547 416 -883
580 -422 205 -887 339 -1370 395 -126 14 -970 22 -1250 11 l-210 -8 430 -7
c784 -14 1000 -29 1310 -92 874 -178 1669 -639 2228 -1289 412 -481 665 -943
841 -1536 73 -243 125 -565 147 -905 12 -174 1 -486 -21 -635 -5 -36 -16 -114
-25 -174 -31 -221 -113 -530 -203 -771 -74 -198 -222 -500 -328 -672 -243
-393 -565 -755 -919 -1034 -440 -346 -969 -603 -1505 -733 -545 -132 -1157
-141 -1725 -24 -106 22 -386 102 -505 145 -363 131 -744 342 -1057 585 -147
114 -178 141 -336 297 -490 480 -834 1059 -1007 1691 -5 19 -19 80 -30 134
-21 105 -21 144 0 54 20 -82 103 -330 159 -470 105 -265 281 -591 427 -789 39
-52 84 -113 101 -137 17 -24 89 -109 161 -190 442 -499 1002 -863 1662 -1080
374 -124 858 -192 1255 -178 582 21 1109 159 1610 421 342 178 614 376 889
644 319 313 543 623 737 1017 90 183 131 285 201 504 114 354 178 763 178
1130 0 291 -63 731 -146 1016 -251 869 -844 1644 -1607 2102 -386 232 -756
373 -1222 466 -201 40 -276 47 -616 53 -378 7 -1374 -6 -1362 -17 4 -5 323
-10 708 -13 551 -3 730 -8 841 -20 628 -71 1124 -244 1617 -563 726 -472 1285
-1251 1491 -2081 88 -352 112 -576 103 -958 -9 -378 -48 -611 -168 -995 -29
-92 -102 -277 -155 -390 -169 -365 -389 -676 -683 -971 -336 -335 -677 -569
-1082 -742 -250 -107 -589 -204 -839 -241 -355 -52 -814 -44 -1145 20 -469 91
-923 282 -1315 552 -207 143 -476 387 -646 587 -323 379 -566 841 -682 1295
-27 107 -55 289 -31 200 151 -547 355 -958 668 -1345 182 -226 497 -506 764
-678 387 -251 857 -426 1312 -491 323 -46 773 -37 1070 20 845 164 1585 639
2073 1331 214 305 369 640 482 1043 21 74 69 330 82 435 17 147 18 626 0 760
-52 388 -189 818 -361 1133 -81 147 -97 174 -167 277 -194 285 -394 500 -676
728 -291 234 -695 438 -1083 548 -313 89 -571 118 -1035 119 -379 0 -1109 -17
-1215 -29 l-70 -8 95 -2 c52 -2 415 -6 805 -11 772 -9 847 -14 1120 -75 715
-159 1324 -543 1772 -1115 292 -372 512 -854 588 -1285 83 -469 68 -929 -44
-1349 -183 -688 -620 -1307 -1206 -1708 -141 -97 -190 -125 -359 -210 -296
-149 -546 -225 -941 -289 -53 -9 -178 -13 -375 -13 -275 1 -306 3 -449 28
-232 41 -285 53 -421 97 -420 137 -732 313 -1065 600 -430 371 -761 903 -891
1436 -45 182 -44 276 0 108 169 -637 534 -1185 1043 -1566 344 -258 723 -426
1155 -514 231 -47 520 -65 756 -47 631 48 1225 308 1705 746 217 199 442 504
590 801 96 193 195 482 227 660 7 39 19 104 27 145 36 189 35 627 -2 830 -33
182 -39 209 -73 330 -94 337 -263 669 -480 949 -98 125 -288 317 -421 424
-409 330 -854 519 -1371 583 -122 15 -229 16 -1090 9 -525 -4 -982 -11 -1015
-15 l-60 -7 60 -2 c33 -1 184 -6 335 -11 151 -6 554 -15 895 -20 741 -11 866
-22 1164 -106 166 -47 257 -82 436 -169 692 -338 1197 -962 1375 -1698 60
-251 64 -283 63 -607 0 -294 -1 -310 -28 -435 -15 -71 -31 -150 -36 -174 -21
-102 -115 -357 -183 -496 -135 -274 -269 -460 -490 -681 -171 -171 -284 -261
-461 -369 -793 -483 -1773 -486 -2576 -8 -174 103 -414 303 -556 462 -283 316
-493 717 -567 1081 -19 95 -38 251 -32 257 3 3 7 -5 10 -18 84 -433 238 -781
485 -1099 79 -102 279 -305 377 -382 432 -341 939 -518 1484 -518 620 0 1170
221 1628 655 298 282 526 682 631 1108 84 335 86 751 6 1067 -72 289 -172 519
-322 744 -351 524 -883 866 -1517 974 -88 15 -168 17 -631 14 -291 -1 -690 -6
-885 -12 -397 -11 -1119 -14 -1128 -5 -3 3 11 56 31 118 45 137 102 331 132
452 13 50 32 124 43 165 11 41 41 154 66 250 26 96 67 252 92 345 25 94 49
186 53 205 5 19 21 80 36 135 31 112 94 349 125 470 30 115 78 295 107 400 14
50 38 140 54 200 15 61 46 178 68 260 22 83 54 204 71 270 17 66 38 140 46
165 8 25 21 75 29 110 8 36 30 124 50 195 35 130 90 336 171 645 24 91 55 208
69 260 61 226 105 400 105 419 0 19 -6 21 -55 21 -65 0 -65 -1 -115 -155 -18
-55 -68 -206 -111 -335 -42 -129 -123 -377 -179 -550 -56 -173 -128 -396 -161
-495 -96 -290 -149 -454 -299 -915 -78 -239 -159 -487 -180 -550 -21 -63 -102
-311 -180 -550 -78 -239 -155 -473 -170 -520 -16 -47 -69 -213 -120 -370 -85
-264 -176 -529 -186 -539 -10 -11 55 261 121 509 31 113 83 310 135 510 21 80
48 181 60 225 44 166 73 274 169 635 16 58 40 148 54 200 14 52 35 131 46 175
12 44 42 159 67 255 26 96 50 194 55 218 5 24 12 48 15 54 4 6 26 85 49 177
24 91 57 218 74 281 59 215 111 415 137 515 13 55 31 120 39 145 8 25 23 81
34 125 10 44 40 157 66 250 25 94 58 213 71 265 14 52 37 138 51 190 50 187
64 253 55 262 -5 5 -31 8 -58 6 -45 -3 -50 -6 -66 -38 -10 -19 -77 -217 -149
-440 -72 -223 -139 -427 -149 -455 -10 -27 -46 -138 -80 -245 -34 -107 -93
-287 -130 -400 -37 -113 -116 -353 -175 -535 -59 -181 -133 -409 -165 -505
-32 -96 -110 -337 -174 -535 -65 -198 -139 -425 -166 -505 -27 -80 -105 -320
-175 -535 -314 -973 -341 -1016 -126 -205 79 296 121 458 146 550 15 52 46
172 70 265 24 94 58 222 75 285 17 63 42 158 56 210 14 52 34 129 45 170 11
41 33 122 49 180 15 58 46 175 69 260 23 85 57 216 77 290 19 74 48 182 63
240 38 141 81 305 126 475 20 77 47 176 60 220 13 44 36 127 50 185 14 58 39
157 56 220 17 63 43 165 59 225 15 61 53 200 83 310 70 256 80 302 72 315 -7
11 -94 14 -111 2 -9 -6 -66 -175 -225 -672 -28 -85 -58 -177 -69 -205 -10 -27
-45 -133 -78 -235 -81 -252 -291 -896 -361 -1110 -119 -358 -182 -554 -291
-890 -62 -190 -143 -437 -180 -550 -37 -113 -89 -270 -114 -350 -26 -80 -53
-161 -60 -180 -8 -19 -24 -69 -36 -110 -37 -124 -219 -670 -230 -688 -7 -13
-8 -8 -4 18 8 48 54 253 59 259 3 6 45 160 105 391 21 80 47 179 58 220 12 41
39 145 61 230 61 238 70 270 76 279 3 5 10 31 15 58 5 26 19 80 30 118 11 39
29 106 40 150 11 44 34 132 51 195 17 63 37 140 45 170 8 30 20 71 26 90 6 19
15 51 19 70 15 67 38 156 89 345 61 228 88 327 145 545 24 91 57 215 73 275
30 114 85 321 122 460 12 44 40 152 64 240 24 88 49 183 56 210 7 28 30 115
52 195 83 311 118 450 118 469 0 19 -6 21 -57 21 l-58 0 -37 -108 c-47 -138
-339 -1029 -520 -1587 -169 -519 -437 -1340 -488 -1495 -110 -331 -334 -1016
-460 -1410 -85 -263 -141 -422 -147 -417 -7 8 48 234 152 622 65 242 96 358
119 450 14 55 35 134 46 175 11 41 39 145 61 230 22 85 50 189 61 230 11 41
35 131 54 200 19 69 43 161 54 205 12 44 34 127 49 185 41 152 127 479 156
590 12 46 65 244 100 375 20 72 51 190 70 264 19 74 42 157 49 185 18 60 57
207 96 361 16 61 36 137 45 170 9 33 30 110 45 170 16 61 41 153 56 205 68
250 101 395 92 407 -10 13 -94 11 -108 -3 -11 -11 -73 -199 -472 -1424 -28
-85 -74 -227 -103 -315 -29 -88 -78 -239 -109 -335 -31 -96 -65 -197 -75 -225
-10 -27 -23 -66 -29 -85 -12 -40 -206 -639 -292 -900 -162 -495 -369 -1128
-454 -1390 -95 -292 -116 -346 -116 -305 0 21 62 271 130 525 23 85 59 223 81
305 52 195 104 394 139 525 15 58 48 184 74 280 25 96 60 227 77 290 17 63 51
192 76 285 25 94 58 217 73 275 16 58 47 177 70 265 64 241 105 398 138 520
92 342 126 471 152 570 16 61 47 180 70 265 53 199 103 388 151 570 21 80 47
179 58 220 30 108 30 107 -30 112 -42 2 -54 -1 -65 -17 -16 -22 -177 -508
-454 -1365 -61 -190 -124 -383 -140 -430 -16 -47 -83 -254 -150 -460 -67 -206
-148 -454 -180 -550 -32 -96 -126 -384 -210 -640 -83 -256 -164 -503 -180
-550 -36 -108 -232 -713 -259 -800 -31 -102 -80 -218 -80 -192 -1 21 66 290
164 657 25 94 58 220 74 280 16 61 46 171 65 245 20 74 52 195 71 269 19 74
40 148 45 165 6 17 21 76 35 131 13 55 45 177 70 270 25 94 59 220 75 280 16
61 50 189 75 285 26 96 60 225 76 285 16 61 45 171 65 245 20 74 39 149 44
165 4 17 22 82 38 145 77 289 124 463 152 565 16 61 39 148 50 195 11 47 29
114 40 150 11 36 31 110 44 165 14 55 44 168 66 250 22 83 46 178 54 213 8 35
16 65 19 68 2 2 141 9 308 14 760 23 1002 30 1259 40 151 6 392 12 535 15 143
3 422 12 620 20 198 8 549 20 780 26 1100 28 1726 47 1970 60 116 6 383 14
595 19 445 9 851 29 865 41 5 5 -23 9 -65 9 -294 2 -1969 50 -2760 80 -274 10
-582 19 -1340 40 -195 5 -449 14 -565 20 -115 5 -322 12 -460 15 -137 3 -358
10 -490 16 -132 6 -401 14 -597 18 -226 4 -362 11 -368 17 -17 17 257 34 700
44 506 12 991 26 1345 40 304 12 748 25 1359 40 214 5 475 14 580 20 105 6
369 15 586 20 217 5 449 12 515 15 66 3 275 10 465 15 517 15 1193 40 1199 45
2 3 -16 5 -40 6 -24 0 -55 2 -69 4 -14 2 -236 8 -495 15 -499 11 -901 24
-1310 40 -137 5 -416 14 -620 20 -203 6 -503 15 -665 21 -162 5 -455 14 -650
19 -195 5 -449 14 -565 20 -115 6 -381 15 -590 20 -748 20 -1332 40 -1445 50
-63 5 -130 10 -147 10 -18 0 -33 4 -33 8 0 5 150 12 333 16 182 4 402 11 487
17 85 5 308 14 495 19 1030 29 1915 56 2265 70 247 10 1029 34 1490 45 124 3
299 10 390 15 91 5 334 14 540 20 206 6 400 14 430 17 l55 7 -40 8 c-22 4
-213 12 -425 17 -212 5 -506 14 -655 20 -328 13 -806 29 -1250 41 -181 5 -438
14 -570 19 -132 6 -469 17 -750 26 -738 22 -1375 43 -1815 60 -212 8 -481 18
-598 22 -117 3 -215 9 -217 13 -5 7 303 29 520 36 77 2 379 11 670 19 292 9
654 20 805 26 151 6 415 14 585 19 171 4 443 13 605 19 400 15 862 30 1295 41
198 5 370 12 381 15 12 3 255 12 540 20 608 17 1087 35 1092 40 8 7 -133 14
-388 20 -146 3 -389 9 -540 15 -151 5 -502 17 -780 25 -278 9 -665 22 -860 30
-195 8 -602 22 -905 30 -302 8 -662 20 -800 25 -137 6 -392 15 -565 20 -173 5
-346 12 -385 15 -38 3 -252 10 -475 15 -457 11 -696 27 -678 46 3 3 180 9 394
14 214 5 508 14 654 19 524 21 1121 42 1460 52 871 25 1785 55 1935 64 55 3
262 10 460 15 198 5 484 14 635 19 151 6 378 13 503 17 222 6 280 17 147 27
-36 3 -285 12 -555 21 -585 19 -1216 41 -1560 55 -137 6 -430 15 -650 21 -220
7 -499 16 -620 21 -121 4 -355 13 -520 19 -165 5 -424 15 -575 21 -151 6 -419
14 -595 19 -526 14 -972 37 -960 48 3 4 101 10 218 13 377 13 807 29 1042 40
127 6 385 14 575 19 674 17 1057 30 1245 40 105 6 334 15 510 20 570 16 985
30 1225 40 129 6 384 15 565 21 378 11 813 34 821 41 3 3 -136 9 -308 12 -172
4 -407 11 -523 17 -115 5 -349 14 -520 19 -170 5 -445 14 -610 19 -874 31
-1192 42 -1410 51 -227 10 -448 17 -1235 40 -184 5 -420 14 -525 20 -104 6
-334 15 -510 21 -176 5 -340 13 -365 18 l-45 7 45 7 c90 12 221 19 560 27 190
5 482 13 650 19 168 6 501 18 740 26 239 8 554 20 700 25 342 14 767 28 1200
40 190 5 428 14 530 20 102 6 331 15 510 20 179 5 447 14 595 19 149 6 430 15
625 21 340 11 427 14 442 18 4 1 9 -2 13 -7z m-7065 -8852 c392 -53 650 -202
769 -442 71 -143 76 -177 76 -477 0 -300 -5 -331 -77 -480 -33 -68 -57 -101
-127 -170 -76 -76 -100 -93 -201 -142 -124 -61 -179 -79 -310 -104 -157 -30
-184 -33 -353 -40 -162 -7 -248 -1 -392 27 -27 5 -86 16 -130 25 -149 29 -336
122 -446 221 -79 71 -154 201 -191 330 -21 72 -23 98 -23 333 0 239 2 260 24
335 65 220 181 358 384 458 109 53 188 79 334 107 187 37 467 45 663 19z
m2495 -13 c164 -31 249 -57 345 -103 206 -100 309 -265 298 -483 -13 -263
-224 -417 -738 -541 -176 -43 -328 -94 -375 -126 l-40 -28 563 -3 562 -2 0
-255 0 -255 -1111 0 -1110 0 3 208 3 207 33 62 c114 209 391 345 987 484 393
92 525 148 525 224 0 52 -59 93 -162 114 -81 17 -337 15 -430 -3 -91 -18 -185
-61 -211 -97 -10 -15 -22 -41 -26 -58 l-6 -31 -335 -1 c-184 0 -341 0 -349 1
-20 0 -6 156 21 235 55 165 140 268 284 342 97 50 178 76 326 102 161 29 160
29 523 26 262 -2 349 -6 420 -19z m-4067 -251 l-3 -236 -660 -2 -660 -2 -18
-79 c-10 -43 -15 -81 -12 -85 4 -3 160 -9 348 -13 377 -7 503 -20 680 -68 141
-39 237 -88 312 -161 101 -97 153 -237 142 -384 -14 -186 -118 -321 -322 -420
-110 -53 -158 -69 -266 -90 -46 -8 -106 -20 -134 -26 -27 -5 -139 -15 -249
-21 -204 -12 -451 -2 -591 24 -243 45 -355 85 -467 168 -135 99 -201 233 -223
453 l-7 67 348 -2 348 -3 6 -30 c24 -116 113 -145 440 -147 337 -1 436 37 417
158 -9 56 -34 84 -101 114 -111 51 -252 60 -896 60 -530 0 -561 1 -556 18 3 9
17 69 30 132 14 63 44 196 67 295 22 99 56 250 75 335 19 85 36 161 39 168 3
9 205 12 960 12 l955 0 -2 -235z m1844 -1764 c2 -17 1 -28 -4 -25 -8 5 -12 54
-4 54 2 0 6 -13 8 -29z m-1325 -553 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-880 -370 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m15143 -445 c4 -4 -20 -73 -128 -378 -39 -107 -40 -110 -84 -131 -24 -12 -61
-41 -82 -64 -64 -71 -133 -238 -157 -382 -14 -87 -7 -138 26 -175 l24 -28 35
34 c39 38 70 99 162 318 237 567 406 739 641 651 81 -30 171 -121 203 -203 29
-76 38 -230 20 -358 -22 -165 -125 -482 -217 -673 -96 -196 -201 -320 -319
-376 -64 -31 -76 -33 -176 -33 -58 0 -109 3 -113 6 -3 4 19 73 48 155 30 82
69 191 87 243 31 85 37 95 68 108 19 8 55 35 81 61 134 134 228 532 136 574
-54 24 -89 -30 -221 -342 -137 -325 -217 -463 -328 -568 -59 -56 -147 -102
-196 -102 -39 0 -125 33 -183 69 -188 120 -211 434 -64 866 110 321 206 503
325 617 100 96 197 131 332 121 41 -3 77 -7 80 -10z m-19490 -700 c185 -50
355 -223 495 -503 104 -207 162 -397 172 -560 12 -203 -30 -323 -154 -447 -40
-40 -80 -73 -88 -73 -10 0 -25 20 -37 48 -11 26 -37 85 -58 132 -21 47 -59
132 -85 190 -39 87 -44 108 -36 128 16 34 13 145 -5 214 -21 85 -96 234 -146
292 -43 50 -122 96 -166 96 -30 0 -165 -54 -203 -81 -45 -32 -69 -88 -69 -162
0 -163 143 -463 253 -531 26 -16 50 -27 53 -24 5 5 -37 106 -112 267 -19 40
-34 78 -34 85 0 10 172 91 193 91 5 0 23 -34 41 -75 18 -41 64 -142 101 -225
65 -145 84 -189 174 -394 23 -52 38 -98 34 -102 -5 -3 -118 -55 -253 -114
-135 -59 -282 -125 -327 -146 -59 -27 -85 -34 -90 -26 -12 19 -93 208 -93 217
0 5 36 29 80 55 44 26 80 48 80 50 0 2 -28 19 -62 37 -197 104 -376 392 -460
738 -28 113 -31 320 -6 398 33 102 103 200 188 264 51 38 209 114 298 144 131
43 211 47 322 17z m18483 -996 c29 -18 87 -52 129 -76 43 -23 80 -46 83 -51 3
-6 -37 -83 -89 -173 -79 -137 -249 -434 -367 -644 l-29 -51 66 -42 c36 -23 70
-38 74 -33 4 4 117 199 250 433 133 234 244 426 245 428 2 2 27 -12 56 -31 30
-18 87 -51 126 -73 40 -21 73 -42 73 -45 0 -4 -33 -64 -74 -135 -40 -71 -151
-264 -245 -429 -195 -340 -185 -297 -80 -357 l48 -28 14 28 c7 15 70 124 138
242 69 118 172 298 229 398 57 101 109 186 115 188 9 4 263 -131 281 -149 3
-2 -111 -207 -252 -453 -141 -247 -306 -534 -365 -639 -60 -104 -115 -194
-122 -199 -10 -6 -121 52 -378 200 -200 114 -447 256 -548 314 -101 58 -184
109 -183 113 0 15 737 1297 745 1297 4 0 31 -15 60 -33z m-17359 -811 c90
-133 132 -203 126 -211 -5 -6 -164 -116 -354 -245 -190 -129 -346 -240 -346
-246 0 -9 89 -144 311 -469 200 -293 242 -356 238 -359 -10 -10 -322 -217
-329 -219 -5 -1 -71 90 -148 203 -76 113 -220 324 -320 470 -99 146 -226 332
-282 414 -56 82 -97 152 -92 155 34 21 825 561 920 628 64 45 122 81 129 80 7
-1 73 -92 147 -201z m16667 -842 c53 -15 173 -76 183 -93 10 -15 -324 -396
-343 -392 -89 21 -191 -29 -298 -143 -196 -210 -222 -347 -91 -474 126 -123
240 -117 385 22 163 154 234 299 203 410 l-12 43 162 192 c90 105 166 191 171
191 17 0 91 -103 125 -174 32 -67 37 -90 42 -172 6 -124 -14 -208 -84 -349
-73 -147 -165 -275 -303 -418 -224 -231 -396 -327 -585 -327 -147 0 -249 45
-406 176 -149 125 -211 211 -244 340 -57 221 59 510 324 806 172 193 337 313
495 359 77 22 205 24 276 3z m-15788 -525 c82 -27 199 -90 284 -152 95 -70
329 -307 392 -397 68 -96 135 -230 155 -307 22 -86 21 -216 -4 -294 -63 -207
-336 -455 -549 -500 -125 -26 -318 33 -491 151 -117 81 -344 303 -427 420
-127 178 -192 357 -178 492 14 152 74 256 233 410 107 102 187 156 276 184 68
21 236 17 309 -7z m14403 -804 c65 -72 175 -193 244 -269 69 -77 183 -203 254
-280 71 -78 154 -169 184 -204 54 -62 55 -63 36 -81 -121 -114 -347 -312 -354
-309 -8 3 -83 84 -385 418 -146 163 -184 204 -282 310 -171 185 -194 214 -180
227 51 51 353 321 358 320 3 -1 59 -61 125 -132z m-512 -290 c3 -8 17 -113 31
-233 14 -119 30 -239 35 -267 5 -27 19 -131 30 -230 19 -168 74 -596 85 -657
4 -24 -3 -32 -68 -75 -40 -26 -138 -93 -218 -149 -215 -149 -201 -142 -246
-117 -21 12 -234 127 -473 256 -532 287 -733 397 -743 406 -4 4 10 19 30 32
21 14 121 82 222 152 101 70 192 127 203 127 10 0 54 -22 96 -49 42 -26 210
-130 372 -231 162 -100 307 -191 322 -201 33 -23 42 -24 34 -3 -3 8 -15 63
-26 122 -74 389 -105 554 -129 675 l-27 138 37 27 c21 15 117 81 213 146 97
66 181 125 187 133 15 17 26 17 33 -2z m-12311 -263 c183 -126 413 -285 512
-353 206 -142 279 -209 321 -294 38 -79 41 -193 7 -268 -47 -101 -160 -190
-256 -200 -20 -2 -39 -6 -42 -9 -3 -3 3 -21 14 -39 24 -43 51 -133 51 -174 0
-53 -39 -149 -81 -200 -78 -94 -181 -138 -290 -123 -107 14 -167 49 -539 308
-25 17 -142 98 -260 180 -118 82 -236 163 -262 182 -26 18 -90 62 -142 98 -53
36 -96 68 -96 72 0 8 19 35 347 508 124 179 257 373 296 432 40 59 76 108 80
108 5 0 158 -103 340 -228z m10620 -983 c83 -28 121 -51 190 -118 165 -158
192 -407 67 -604 -16 -26 -30 -51 -30 -55 0 -4 86 -54 191 -111 106 -58 186
-108 182 -112 -9 -9 -103 -51 -357 -161 -113 -49 -119 -50 -145 -36 -166 98
-309 169 -331 164 -26 -5 -369 -154 -383 -166 -7 -6 18 -73 77 -203 36 -79 34
-90 -16 -109 -24 -9 -125 -52 -226 -96 -100 -44 -184 -76 -187 -72 -3 5 -12
26 -20 47 -7 21 -35 85 -60 143 -26 58 -60 134 -75 170 -16 36 -46 106 -68
155 -21 50 -78 180 -126 290 -48 110 -104 235 -124 278 -21 47 -32 81 -26 87
8 8 194 90 654 290 85 37 211 92 280 123 254 113 262 115 378 116 69 1 108 -4
155 -20z m-8701 -140 c90 -40 198 -87 240 -105 42 -18 90 -39 106 -48 l29 -15
12 -173 c7 -95 17 -220 22 -278 11 -141 46 -572 64 -800 8 -101 12 -186 10
-188 -2 -3 -118 45 -257 107 l-253 110 -5 121 -5 121 -85 38 c-47 22 -121 55
-165 74 -44 19 -132 58 -196 86 -64 28 -123 51 -132 51 -9 0 -55 -34 -102 -76
l-86 -76 -67 30 c-96 43 -403 177 -426 186 -19 7 -19 7 2 29 41 40 1107 876
1119 876 7 1 85 -31 175 -70z m1214 -473 c228 -53 312 -74 322 -80 5 -3 -6
-67 -25 -143 -18 -76 -48 -203 -67 -283 -19 -80 -49 -207 -67 -283 -18 -75
-30 -139 -28 -140 4 -4 227 -57 534 -128 99 -22 236 -55 305 -73 121 -30 125
-32 122 -56 -5 -36 -81 -362 -85 -367 -3 -2 -100 19 -218 47 -118 28 -270 64
-339 80 -69 16 -203 48 -297 70 -95 22 -248 58 -340 79 -93 22 -184 43 -203
46 -70 14 -69 11 -39 137 15 62 46 194 69 293 23 99 55 234 71 300 74 310 119
506 119 520 0 20 -7 20 166 -19z m5758 -127 c21 -84 35 -158 32 -165 -3 -8
-14 -14 -24 -14 -10 0 -142 -29 -293 -65 -151 -36 -321 -77 -379 -90 -219 -52
-261 -63 -264 -66 -2 -2 3 -37 12 -77 l16 -74 40 6 c23 4 113 24 201 45 88 22
241 58 340 81 99 23 227 55 285 70 58 16 107 27 109 24 9 -9 68 -285 61 -289
-3 -2 -82 -22 -176 -45 -93 -22 -214 -52 -269 -65 -274 -66 -485 -115 -499
-115 -9 0 -16 -6 -16 -12 0 -27 33 -148 40 -148 16 0 652 149 855 201 44 11
87 21 95 22 10 1 23 -31 46 -122 49 -186 50 -199 22 -205 -54 -12 -421 -98
-513 -121 -55 -13 -197 -47 -315 -75 -118 -28 -260 -62 -315 -75 -218 -52
-287 -67 -291 -62 -3 2 -23 80 -45 173 -22 93 -55 232 -74 309 -18 77 -52 221
-75 320 -24 99 -57 237 -76 307 -18 70 -29 130 -25 132 7 5 181 47 746 181
490 116 576 137 630 150 86 22 79 31 119 -136z m-2179 -233 c291 -32 427 -117
500 -311 39 -103 38 -105 -47 -105 -40 0 -156 -5 -256 -11 l-184 -11 -31 30
c-18 17 -54 41 -82 54 -46 21 -64 23 -215 23 -144 -1 -175 -4 -239 -24 -89
-27 -114 -42 -130 -78 -10 -23 -9 -29 8 -46 27 -28 90 -36 286 -37 288 -1 498
-16 607 -44 139 -35 211 -70 262 -127 49 -54 66 -102 66 -186 0 -226 -185
-371 -560 -440 -140 -25 -694 -26 -792 -1 -112 29 -208 80 -266 142 -53 56
-105 154 -118 221 l-6 35 93 0 c51 0 167 5 256 11 l163 11 36 -32 c99 -87 261
-108 484 -63 118 23 180 63 180 115 0 22 -7 31 -27 39 -16 7 -187 17 -403 25
-348 12 -382 15 -475 39 -165 43 -243 89 -292 172 -25 43 -28 57 -28 138 0 82
3 97 33 157 91 185 349 287 779 307 251 12 258 12 398 -3z"/>
<path d="M9316 12809 c-188 -20 -291 -82 -332 -201 -27 -76 -27 -339 -1 -414
40 -114 126 -170 306 -200 88 -15 116 -16 186 -6 245 36 335 127 351 354 17
241 -53 386 -209 437 -50 16 -213 45 -220 40 -1 -1 -37 -5 -81 -10z"/>
<path d="M5280 5580 c-54 -10 -91 -33 -146 -92 -123 -129 -96 -245 101 -444
185 -186 300 -206 435 -74 66 64 93 121 88 179 -9 93 -88 210 -220 327 -94 83
-181 119 -258 104z"/>
<path d="M6846 4349 c-35 -42 -59 -88 -53 -104 3 -7 90 -71 194 -142 264 -183
294 -203 302 -203 3 0 21 9 39 20 56 34 64 96 19 138 -28 25 -440 310 -460
318 -8 3 -25 -8 -41 -27z"/>
<path d="M6542 3910 c-23 -32 -42 -64 -42 -69 0 -6 54 -48 120 -93 66 -46 190
-131 275 -190 123 -87 160 -108 188 -108 46 0 76 26 84 72 8 54 36 32 -530
421 -22 15 -43 27 -47 27 -3 0 -25 -27 -48 -60z"/>
<path d="M17215 3268 c-91 -41 -145 -65 -360 -158 -66 -29 -121 -54 -123 -55
-3 -2 73 -183 80 -190 5 -6 60 17 411 170 193 84 207 96 207 171 0 28 -8 44
-34 70 -44 44 -68 43 -181 -8z"/>
<path d="M8790 3137 c-25 -23 -92 -82 -148 -131 -56 -48 -102 -91 -102 -95 0
-7 248 -118 285 -127 l25 -6 -1 108 c-1 60 -4 150 -8 201 l-6 92 -45 -42z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
